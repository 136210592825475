export default function styles(theme) {
    return {
        body: {
            fontSize: '1.5rem', 
            color: theme.palette.text.primary, 
            textAlign: "left", 
            marginLeft: "10vw", 
            marginRight: "10vw", 
            marginTop: "2.5vh"
        }
    };
}