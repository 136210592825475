export default function styles(theme) {
  return {
    title: {
      textDecoration: "underline",
    },
    scoring: {
      fontFamily: "times!important",
      textAlign: "center",
    },
  };
}
